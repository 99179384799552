import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../RichText'
import LinkBlock from '../LinkBlock'
import IconWebsite from '../_svgs/IconWebsite'
import IconTwitter from '../_svgs/IconTwitter'
import IconInstagram from '../_svgs/IconInstagram'
import IconFacebook from '../_svgs/IconFacebook'
import IconMail from '../_svgs/IconMail'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import PageTransitionLink from '../PageTransitionLink'
import {
  ExhbitIntro,
  ExhibitName,
  ExhbitTitleWrapper,
  ExhbitLabel,
  ExhbitIntroText,
  ExhbitIntroMain,
  ExhibitIntroFooter,
  ExhibitIntroLabel,
  ExhibitBookBtn,
  ExhibitNext,
  ExhibitNextLabelRight,
  ExhibitNextLabelDown,
  ExhibitNextName,
  ExhibitTitleImage,
  ExhibitTitleImageWrapper,
} from './index.style'
import { ScrollWrapper } from '../Scroll/index.style'
import { colors } from '../../styles/vars/colors.style'
import ScrollSection from '../ScrollSection'
import { animation } from '../../styles/vars/animation.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import Gallery from '../Gallery'
import SlideMask from '../SlideMask'

const Exhibit = ({
  type,
  title,
  name,
  nameImage,
  label,
  intro,
  website,
  contactEmailAddress,
  instagramHandle,
  twitterHandle,
  facebookUrl,
  bookingUrl,
  art,
  next,
}) => {
  const entryDelay = animation.pageEntryDuration
  const urlPrefix =
    type === 'artist'
      ? '/artists/'
      : type === 'event'
      ? '/events/'
      : type === 'partner'
      ? '/partners/'
      : ''
  const typeLabel =
    type === 'artist' ? 'Artists' : type === 'event' ? 'Events' : name
  const galleryName = type === 'artist' ? name : label

  return (
    <ScrollWrapper color={colors.light}>
      <ScrollSection width={1570}>
        <ExhbitIntro>
          <ExhbitTitleWrapper>
            <ExhibitTitleImageWrapper>
              <AnimateSlideIn delay={entryDelay} direction="right">
                {nameImage && <ExhibitTitleImage imgUrl={nameImage.file.url} />}
              </AnimateSlideIn>
            </ExhibitTitleImageWrapper>
          </ExhbitTitleWrapper>

          <ExhbitIntroMain>
            <ExhibitIntroLabel>
              <AnimateSlideIn direction="right" delay={entryDelay}>
                {typeLabel}
              </AnimateSlideIn>
            </ExhibitIntroLabel>

            {name && (
              <ExhibitName>
                <AnimateSplitText delay={entryDelay}>{name}</AnimateSplitText>
              </ExhibitName>
            )}

            {label && (
              <ExhbitLabel>
                <AnimateSplitText delay={entryDelay}>{label}</AnimateSplitText>
              </ExhbitLabel>
            )}

            {intro && (
              <ExhbitIntroText>
                <RichText content={intro} delay={entryDelay} />
              </ExhbitIntroText>
            )}

            {bookingUrl && (
              <AnimateSlideIn delay={entryDelay}>
                <ExhibitBookBtn href={bookingUrl} target="_blank">
                  <SlideMask>Purchase Tickets</SlideMask>
                </ExhibitBookBtn>
              </AnimateSlideIn>
            )}

            {(website ||
              contactEmailAddress ||
              instagramHandle ||
              facebookUrl ||
              twitterHandle) && (
              <>
                <ExhibitIntroFooter>
                  <AnimateSlideIn delay={entryDelay + 0.8}>
                    {type === 'artist'
                      ? 'Explore this artist'
                      : type === 'partner'
                      ? 'More from this partner'
                      : ''}
                  </AnimateSlideIn>
                </ExhibitIntroFooter>

                {contactEmailAddress && (
                  <AnimateSlideIn delay={entryDelay + 0.8}>
                    <LinkBlock
                      url={`mailto:${contactEmailAddress}`}
                      icon={<IconMail />}
                      text={contactEmailAddress}
                    />
                  </AnimateSlideIn>
                )}

                {website && (
                  <AnimateSlideIn delay={entryDelay + 0.8}>
                    <LinkBlock
                      url={website}
                      icon={<IconWebsite />}
                      text={website
                        .replace(/(^\w+:|^)\/\//, '')
                        .replace(/\/$/, '')}
                    />
                  </AnimateSlideIn>
                )}

                {instagramHandle && (
                  <AnimateSlideIn delay={entryDelay + 0.8}>
                    <LinkBlock
                      url={`https://www.instagram.com/${instagramHandle}`}
                      icon={<IconInstagram />}
                      text={`@${instagramHandle}`}
                    />
                  </AnimateSlideIn>
                )}

                {twitterHandle && (
                  <AnimateSlideIn delay={entryDelay + 0.8}>
                    <LinkBlock
                      url={`https://www.twitter.com/${twitterHandle}`}
                      icon={<IconTwitter />}
                      text={`@${twitterHandle}`}
                    />
                  </AnimateSlideIn>
                )}

                {facebookUrl && (
                  <AnimateSlideIn delay={entryDelay + 0.8}>
                    <LinkBlock
                      url={facebookUrl}
                      icon={<IconFacebook />}
                      text={`@${facebookUrl
                        .replace(/^.+.com\//, '')
                        .replace(/\/$/, '')}`}
                    />
                  </AnimateSlideIn>
                )}
              </>
            )}
          </ExhbitIntroMain>
        </ExhbitIntro>
      </ScrollSection>

      {art && (
        <Gallery
          art={art}
          title={title}
          name={galleryName}
          typeLabel={typeLabel}
          next={next}
          urlPrefix={urlPrefix}
        />
      )}

      {next && (
        <ScrollSection width={254}>
          <ExhibitNext>
            <PageTransitionLink to={`${urlPrefix}${next.slug}`} fill>
              <ExhibitNextLabelRight aria-hidden>
                <AnimateSlideIn>Next</AnimateSlideIn>
              </ExhibitNextLabelRight>

              <ExhibitNextLabelDown aria-hidden>
                <AnimateSlideIn>
                  <SlideMask direction="horizontal">Next</SlideMask>
                </AnimateSlideIn>
              </ExhibitNextLabelDown>

              <ExhibitNextName aria-hidden>
                <AnimateSplitText>{next.name}</AnimateSplitText>
              </ExhibitNextName>
            </PageTransitionLink>
          </ExhibitNext>
        </ScrollSection>
      )}
    </ScrollWrapper>
  )
}

Exhibit.propTypes = {
  type: PropTypes.oneOf(['artist', 'event', 'partner']),
  title: PropTypes.string,
  name: PropTypes.string,
  nameImage: PropTypes.object,
  label: PropTypes.string,
  intro: PropTypes.object,
  website: PropTypes.string,
  contactEmailAddress: PropTypes.string,
  bookingUrl: PropTypes.string,
  instagramHandle: PropTypes.string,
  twitterHandle: PropTypes.string,
  facebookUrl: PropTypes.string,
  art: PropTypes.array,
  next: PropTypes.object,
}

export default Exhibit
