import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Exhibit from '../components/Exhibit'

const ArtistTemplate = ({ data, pageContext }) => {
  const {
    seoDescription,
    name,
    nameImage,
    exhibitionName,
    exhibitionDate,
    exhibitionIntro,
    artistWebsite,
    instagramHandle,
    twitterHandle,
    contactEmailAddress,
    art,
  } = data.contentfulArtist

  return (
    <>
      <SEO title={name} description={seoDescription.seoDescription} />
      <Exhibit
        type="artist"
        title={name}
        nameImage={nameImage}
        name={exhibitionName}
        label={exhibitionDate}
        intro={exhibitionIntro}
        website={artistWebsite}
        contactEmailAddress={contactEmailAddress}
        instagramHandle={instagramHandle}
        twitterHandle={twitterHandle}
        art={art}
        next={pageContext.next}
      />
    </>
  )
}

export default ArtistTemplate

export const pageQuery = graphql`
  query ArtistBySlug($slug: String!) {
    contentfulArtist(slug: { eq: $slug }) {
      seoDescription {
        seoDescription
      }
      name
      nameImage {
        file {
          url
        }
      }
      artistWebsite
      contactEmailAddress
      instagramHandle
      twitterHandle
      exhibitionDate
      exhibitionIntro {
        raw
      }
      exhibitionName
      art {
        type: __typename
        ... on Node {
          ... on ContentfulImage {
            title
            description
            file {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
              }
              gatsbyImageData(quality: 80)
            }
          }
          ... on ContentfulVideo {
            title
            description
            vimeoId
          }
        }
      }
    }
  }
`
