import styled, { css } from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { flow, horizontalPadding } from '../../styles/utils/functions.style'
import { colors } from '../../styles/vars/colors.style'
import { SplitTextWrapper } from '../_animation/AnimateSplitText/index.style'
import {
  AnimateSlideInContent,
  AnimateSlideInMask,
} from '../_animation/AnimateSlideIn/index.style'
import { easings } from '../../styles/vars/easings.style'

export const ExhbitIntro = styled.div`
  ${mq.horizontal} {
    display: flex;
    width: 100%;
    height: ${horizontalScale(895)};
    overflow: hidden;
  }
`

export const ExhbitTitleWrapper = styled.div`
  padding-top: 11.11rem;
  padding-bottom: 2rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    position: relative;
    order: 1;
    width: ${horizontalScale(910)};
    padding: 0;
  }
`

export const ExhibitTitleImageWrapper = styled.div`
  display: block;
  width: 100%;
  height: calc(100vw - 6.4rem * 0.9835164835);

  ${mq.horizontal} {
    height: 100%;
  }

  ${AnimateSlideInMask},
  ${AnimateSlideInContent} {
    width: 100%;
    height: 100%;
  }
`

export const ExhibitTitleImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${mq.horizontal} {
    background-position: right center;
  }
`

export const ExhbitIntroMain = styled.div`
  ${horizontalPadding()}
  padding-bottom: 2rem;

  ${mq.horizontal} {
    position: relative;
    width: ${horizontalScale(660)};
    padding-left: ${horizontalScale(180)};
    padding-right: ${horizontalScale(80)};
    padding-bottom: 0;
  }
`

export const ExhibitIntroLabel = styled.div`
  position: absolute;
  top: ${horizontalScale(4)};
  left: ${horizontalScale(110)};
  transform-origin: 0% 0%;
  transform: rotate(90deg);
  display: none;
  font-size: ${horizontalScale(12)};
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    display: block;
  }
`

export const ExhibitName = styled.h3`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(38)};
  }
`

export const ExhbitLabel = styled.h4`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(4)};
    font-size: ${horizontalScale(28)};
  }
`

export const ExhibitBookBtn = styled.a`
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  display: inline-flex;
  padding: 1.8rem 2.4rem 1.7rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 2.4rem;
  line-height: 1.2;
  text-transform: uppercase;
  border: 0.1rem solid ${colors.dark};

  ${mq.horizontal} {
    margin-bottom: 0;
    padding-top: ${horizontalScale(18)};
    padding-right: ${horizontalScale(24)};
    padding-left: ${horizontalScale(24)};
    padding-bottom: ${horizontalScale(17)};
    font-size: ${horizontalScale(24)};
  }
`

export const ExhbitIntroText = styled.div`
  ${flow(1.5)}

  ${mq.belowHorizontal} {
    margin-top: 6rem;
  }

  ${mq.horizontal} {
    ${ExhibitName} + &,
    ${ExhbitLabel} + & {
      margin-top: ${horizontalScale(32)};
    }
  }

  p {
    font-weight: ${font.weight.light};
    font-size: 2.1rem;
    line-height: 1.5;
    letter-spacing: -0.02em;

    ${mq.horizontal} {
      font-size: ${horizontalScale(21)};
    }
  }
`

export const ExhibitIntroFooter = styled.h3`
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(74)};
    margin-bottom: ${horizontalScale(16)};
    font-size: ${horizontalScale(28)};
  }
`

export const ExhibitNext = styled.div`
  position: relative;
  padding: 4.7rem 3.2rem 0 5.8rem;
  overflow: hidden;
  color: ${colors.light};
  background-color: ${colors.dark};

  ${mq.horizontal} {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  ${SplitTextWrapper} {
    ${mq.belowHorizontal} {
      transform: translateY(0.9rem);
    }
  }
`

export const ExhibitNextName = styled.div`
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 6.8rem;
  line-height: 0.84;
  text-transform: uppercase;
  letter-spacing: -0.03em;

  ${mq.horizontal} {
    position: absolute;
    top: ${horizontalScale(248)};
    left: ${horizontalScale(210)};
    transform-origin: 0% 0%;
    transform: rotate(90deg);
    font-size: ${horizontalScale(188)};
    white-space: nowrap;
  }
`

const ExhibitNextLabelStyles = css`
  position: absolute;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 1.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(58)};
    font-variation-settings: ${font.weight.secondary.extrabold};
  }
`

export const ExhibitNextLabelDown = styled.p`
  ${ExhibitNextLabelStyles}
  top: 3.2rem;
  left: 5rem;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateZ(0);

  ${mq.horizontal} {
    top: ${horizontalScale(60)};
    left: ${horizontalScale(120)};

    :before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform-origin: 0% 50%;
      transform: scaleX(0) translateZ(0);
      display: block;
      height: 0.2rem;
      background-color: ${colors.light};
      transition: transform 0.3s ${easings.out.cubic};

      ${ExhibitNext}:hover & {
        transform: scaleX(1) translateZ(0);
      }
    }
  }
`

export const ExhibitNextLabelRight = styled.p`
  ${ExhibitNextLabelStyles}
  top: 3rem;
  left: 5.8rem;

  ${mq.horizontal} {
    display: none;
  }
`
