import React from 'react'

const IconMail = ({
  width = 14,
  height = 11,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 11"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1H2a1 1 0 00-1 1v7a1 1 0 001 1h10a1 1 0 001-1V2a1 1 0 00-1-1zM2 0a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2V2a2 2 0 00-2-2H2z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.646 7.354l-6-6 .708-.708L7 6.293 12.646.646l.708.708-6 6a.5.5 0 01-.708 0z"
      />
    </svg>
  )
}

export default IconMail
