import React from 'react'

const IconTwitter = ({
  width = 14,
  height = 12,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M14 1.34692C13.4849 1.57558 12.9313 1.72958 12.3503 1.799C12.9436 1.44375 13.3992 0.880833 13.6132 0.21C13.0585 0.539 12.4437 0.778167 11.7892 0.907083C11.2659 0.348833 10.5187 0 9.69267 0C7.83825 0 6.47558 1.73017 6.89442 3.52625C4.508 3.40667 2.39167 2.26333 0.97475 0.525583C0.22225 1.8165 0.5845 3.50525 1.86317 4.36042C1.393 4.34525 0.949667 4.21633 0.562917 4.00108C0.531417 5.33167 1.48517 6.5765 2.8665 6.85358C2.46225 6.96325 2.0195 6.98892 1.56917 6.90258C1.93433 8.04358 2.99483 8.87367 4.2525 8.897C3.045 9.84375 1.52367 10.2667 0 10.087C1.27108 10.9019 2.78133 11.3773 4.403 11.3773C9.73583 11.3773 12.7487 6.87342 12.5667 2.83383C13.1279 2.42842 13.615 1.92267 14 1.34692V1.34692Z"
      />
    </svg>
  )
}

export default IconTwitter
